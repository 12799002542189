import React, { useEffect, useState } from "react";
import { Button, Card, Pagination, Table } from "react-bootstrap";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import {
  productGetOne,
  productPackageGetMany,
} from "../../../services/therdy/product.service";
import { URL_PHOTO_AW3 } from "../../../constants";
import moment from "moment";
import PopUpAddPackage from "../../../components/popup/therdy/PopUpAddPackage";
import useQuery from "../../../helpers/useQuery";

// icons
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import PopUpDeleteProductPackage from "../../../components/popup/therdy/PopUpDeleteProductPackage";
import PopUpUpdatePackage from "../../../components/popup/therdy/PopUpUpdatePackage";
import PopUpDeleteProduct from "../../../components/popup/therdy/PopUpDeleteProduct";
import PopUpUpdateProduct from "../../../components/popup/therdy/PopUpUpdateProduct";
import ConvertType from "../../../helpers/ConvertType";
import ConvertActive from "../../../helpers/ConvertActive";

export default function ProductDetailPage() {
  const { productId } = useQuery();

  const _id = productId;

  // state
  const [pagination, setPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(10);
  const [productData, setProductData] = useState<any>();
  const [productPackageData, setProductPackageData] = useState<any[]>([]);

  const [popup, setPopup] = useState<any>({});

  // useEffect
  useEffect(() => {
    getData();
  }, []);
  // function
  const getData = async () => {
    try {
      const data = await productGetOne(_id, "");
      const _packages = await productPackageGetMany(_id, "", "");
      setProductPackageData(_packages);
      setProductData(data);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <React.Fragment>
      <div className="p-2 md:p-4">
        <Breadcrumb>
          <Breadcrumb.Item>THERDY CO</Breadcrumb.Item>
          <Breadcrumb.Item>ລາຍການສິນຄ້າ</Breadcrumb.Item>
          <Breadcrumb.Item active>{productData?.name || _id}</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs defaultActiveKey="user-list">
          <Tab eventKey="user-list" title="ລາຍລະອຽດສິນຄ້າ" className="pt-2">
            <Card border="primary" className="mb-2">
              <Card.Header className="flex items-center">
                ລາຍລະອຽດສິນຄ້າ
              </Card.Header>
              <Card.Body>
                <div className="flex gap-4 md:flex-row flex-col">
                  <div>
                    <img
                      src={URL_PHOTO_AW3 + productData?.image}
                      alt=""
                      className="w-[250px] h-[250px] object-cover rounded-lg"
                    />
                  </div>
                  <div className="flex-1">
                    <div>_id: {productData?._id || "--"}</div>
                    <div>name: {productData?.name || "--"}</div>
                    <div>type: {productData?.type || "--"}</div>
                    <div>active: {productData?.active || "--"}</div>
                    <div className="flex gap-2">
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          setPopup({ PopUpUpdateProduct: _id });
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        disabled
                        variant="outline-danger"
                        onClick={() => {
                          setPopup({ PopUpDeleteProduct: _id });
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div className="mb-2">
              <Button onClick={() => setPopup({ PopUpAddPackage: true })}>
                Add Package
              </Button>
            </div>
            <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 gap-2">
              {productPackageData?.map((value: any, index: any) => (
                <Card key={index}>
                  <div className="aspect-square p-2">
                    <div className="rounded-lg overflow-hidden">
                      <img
                        src={
                          value?.image
                            ? URL_PHOTO_AW3 + value?.image
                            : "/images/profile.png"
                        }
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </div>
                  <div className="pl-2 text-xs">{value?.name ?? "--"}</div>
                  <div className="pl-2 text-xs">{value?.price ?? "--"}</div>
                  <div className="flex flex-col gap-2 p-2 justify-end h-full">
                    <ConvertActive text={value?.active ?? "--"} />
                  </div>
                  <div className="flex flex-col gap-2 p-2 justify-end h-full">
                    <Button
                      size="sm"
                      onClick={() => {
                        setPopup({ PopUpUpdatePackage: value?._id });
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={() =>
                        setPopup({
                          PopUpDeleteProductPackage: value?._id,
                        })
                      }
                    >
                      Delete
                    </Button>
                  </div>
                </Card>
              ))}
            </div>
            {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    bottom: 20,
                  }}
                >
                  <ReactPaginate
                    previousLabel={
                      <span className="glyphicon glyphicon-chevron-left">
                        <FaAngleLeft />
                      </span>
                    }
                    nextLabel={
                      <span className="glyphicon glyphicon-chevron-right">
                        <FaAngleRight />
                      </span>
                    }
                    breakLabel={
                      <Pagination.Item disabled>
                        <BsThreeDots />
                      </Pagination.Item>
                    }
                    breakClassName={"break-me"}
                    pageCount={totalPagination} // Replace with the actual number of pages
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={(e) => {
                      console.log(e);
                      setPagination(e?.selected + 1);
                    }}
                    containerClassName={"pagination justify-content-center"} // Bootstrap class for centering
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                  />
                </div> */}
          </Tab>
          <Tab eventKey="history" title="ປະຫວັດລາຍການ" disabled></Tab>
        </Tabs>
      </div>

      {/* ----------------- | popup | ----------------- */}
      <PopUpAddPackage
        open={popup?.PopUpAddPackage}
        onClose={() => {
          setPopup({});
        }}
        productId={_id}
        callback={getData}
      />
      <PopUpDeleteProductPackage
        open={popup?.PopUpDeleteProductPackage}
        onClose={() => {
          setPopup({});
        }}
        productPackageId={popup?.PopUpDeleteProductPackage}
        callback={getData}
      />
      <PopUpUpdatePackage
        productPackageId={popup?.PopUpUpdatePackage}
        open={popup?.PopUpUpdatePackage}
        onClose={() => {
          setPopup({});
        }}
        callback={getData}
      />

      <PopUpDeleteProduct
        open={popup?.PopUpDeleteProduct}
        onClose={() => {
          setPopup({});
        }}
        productId={popup?.PopUpDeleteProduct}
      />
      <PopUpUpdateProduct
        productId={popup?.PopUpUpdateProduct}
        open={popup?.PopUpUpdateProduct}
        onClose={() => {
          setPopup({});
        }}
      />
    </React.Fragment>
  );
}
