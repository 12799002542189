import React from "react";
import { RiAdminFill } from "react-icons/ri";
import { RiRadioButtonLine } from "react-icons/ri";
type Props = {
  text: string;
};

export default function ConvertType({ text }: Props) {
  switch (text) {
    case "MOOGOLD":
      return (
        <div className="p-1 text-xs border rounded-lg bg-white shadow-sm">
          <img src="/images/icon/moogold.webp" className="w-4 h-4" /> {text}
        </div>
      );
    case "CODASHOP":
      return (
        <div className="p-1 text-xs border rounded-lg bg-[#3c1f42] shadow-sm text-white">
          <img src="/images/icon/codashop.png" className="w-4 h-4" /> {text}
        </div>
      );
    case "ADMIN":
    default:
      return (
        <div className="p-1 text-xs border rounded-lg bg-[#FBF5E5] shadow-sm text-yellow-700">
          <RiAdminFill /> {text}
        </div>
      );
  }
}
