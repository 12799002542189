import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card } from "react-bootstrap";
import ReportChartDayOfWeek from "../../../components/report_chart/ReportChartDayOfWeek";
import { useStore } from "../../../store";
import PopUpSetStartAndEndDate from "../../../components/popup/PopUpSetStartAndEndDate";
import moment from "moment";
import { BsFillCalendarWeekFill } from "react-icons/bs";
import { useQuery } from "react-query";
import { getLocalData } from "../../../constants/api";
import {
  reportPayment,
  reportUser,
} from "../../../services/therdy/report.service";
import { moogoldGetOne } from "../../../services/therdy/moogold.service";

type Props = {};

export default function ReportPage({}: Props) {
  // const { myAudio }: any = useStore();
  // useEffect(() => {
  //   myAudio.addEventListener(
  //     "ended",
  //     function () {
  //       myAudio.currentTime = 0;
  //       myAudio.play();
  //     },
  //     false
  //   );
  //   myAudio.play();
  //   return () => {
  //     myAudio.pause();
  //     myAudio.currentTime = 0;
  //   };
  // }, []);

  // function
  const { data, refetch } = useQuery(
    ["report"],
    async () => {
      const { TOKEN } = await getLocalData();
      let findby = "";

      return await reportUser(findby, TOKEN.authorization);
    },
    {
      keepPreviousData: true,
    }
  );
  const { data: payment } = useQuery(
    ["report-payment"],
    async () => {
      const { TOKEN } = await getLocalData();
      let findby = "";

      return await reportPayment(findby, TOKEN.authorization);
    },
    {
      keepPreviousData: true,
    }
  );
  const { data: moogold } = useQuery(
    ["report-moogold"],
    async () => {
      const { TOKEN } = await getLocalData();
      let findby = "";

      return await moogoldGetOne(findby, TOKEN.authorization);
    },
    {
      keepPreviousData: true,
    }
  );

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [startTime, setStartTime] = useState("00:00:00");
  const [endTime, setEndTime] = useState("23:59:59");
  const [popup, setPopup] = useState<any>({});
  return (
    <div className="p-2 md:p-4">
      <Breadcrumb>
        <Breadcrumb.Item>THERDY CO</Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍງານ</Breadcrumb.Item>
      </Breadcrumb>
      <div className="mb-2">
        <Button
          variant="primary"
          onClick={() => setPopup({ popupfiltter: true })}
          className="flex text-sm md:text-md gap-[10px] items-center w-full md:max-w-[440px]"
        >
          <div className="hidden md:block">
            <BsFillCalendarWeekFill />
          </div>
          <div>
            {startDate} {startTime}
          </div>{" "}
          ~{" "}
          <div>
            {endDate} {endTime}
          </div>
        </Button>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
        <Card>
          <Card.Header>MooGold</Card.Header>
          <Card.Body>
            <li>{moogold?.balance}</li>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>MBLaos</Card.Header>
          <Card.Body>
            <li>0 LAK</li>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Binance</Card.Header>
          <Card.Body>
            <li>0 USDT</li>
            <li>0 TRX</li>
            <li>0 ETH</li>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Profit</Card.Header>
          <Card.Body>
            <li>0 USDT</li>
            <li>0 LAK</li>

            <hr />
            <li>Total ≈ 0 USD</li>
          </Card.Body>
        </Card>
        <Card style={{ gridColumn: "span 2" }}>
          <Card.Header>Users</Card.Header>
          <Card.Body>{data?.count}</Card.Body>
          <Card.Body>
            {data && (
              <ReportChartDayOfWeek
                data={data?.date?.map((e: any) => ({
                  xaxis: e.date,
                  data: e.count,
                }))}
              />
            )}
          </Card.Body>
        </Card>
        <Card style={{ gridColumn: "span 2" }}>
          <Card.Header>Payment Transection</Card.Header>
          <Card.Body>{payment?.count}</Card.Body>
          <Card.Body>
            {payment && (
              <ReportChartDayOfWeek
                data={payment?.date?.map((e: any) => ({
                  xaxis: e.date,
                  data: e.totalAmount,
                }))}
              />
            )}
          </Card.Body>
        </Card>
      </div>
      {/* popup */}

      <PopUpSetStartAndEndDate
        open={popup?.popupfiltter}
        onClose={() => setPopup({})}
        startDate={startDate}
        setStartDate={setStartDate}
        setStartTime={setStartTime}
        startTime={startTime}
        setEndDate={setEndDate}
        setEndTime={setEndTime}
        endTime={endTime}
        endDate={endDate}
      />
    </div>
  );
}
