import axios from "axios";
import { WarningNetworkFailure } from "../../helpers/alertsweet";
import { Order } from "../../interfaces/Order";

const END_POINT_SEVER =
  process.env.REACT_APP_THERDY_API_URL || "https://minion-api.nonlub.com";

export const orderGetMany = async (findby: string, token: string) => {
  const url = END_POINT_SEVER + "/order/get-many?" + findby;
  const res = await axios.get(url, {
    headers: { authorization: "Bearer " + token },
  });
  return res.data;
};

export const orderSmsCallback = async (
  body: { text: string },
  token: string
) => {
  try {
    const url = END_POINT_SEVER + "/order/sms-callback";
    const res = await axios.post(url, body, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const orderUpdateOne = async (
  _id: string,
  body: Order,
  token: string
) => {
  const url = END_POINT_SEVER + "/order/update-one";
  const res = await axios.put(
    url,
    { id: _id, data: body },
    {
      headers: { authorization: "Bearer " + token },
    }
  );
  return res.data;
};

export const orderGetOne = async (_id: string, token: string) => {
  const url = END_POINT_SEVER + "/order/get-one/" + _id;
  const res = await axios.get(url, {
    headers: { authorization: "Bearer " + token },
  });
  return res.data;
};
