import React from "react";
import { RiAdminFill } from "react-icons/ri";
import { RiRadioButtonLine } from "react-icons/ri";
type Props = {
  text: string;
};

export default function ConvertActive({ text }: Props) {
  switch (text) {
    case "PUBLIC":
      return (
        <div className="text-green-600 text-xs">
          <RiRadioButtonLine /> {text}
        </div>
      );
    case "VIP":
      return (
        <div className="text-yellow-600 text-xs">
          <RiRadioButtonLine /> {text}
        </div>
      );
    case "ADMIN":
    default:
      return (
        <div className="text-red-600 text-xs">
          <RiAdminFill /> {text}
        </div>
      );
  }
}
