import * as Yup from "yup";

const validationSchema = {
  createProduct: Yup.object({
    image: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    active: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
  }),
  createPackage: Yup.object({
    // image: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    price: Yup.number().required("Required"),
    active: Yup.string().required("Required"),
  }),
  createPaymentTransaction: Yup.object({
    text: Yup.string().required("Required"),
  }),
  updateOrder: Yup.object({
    status: Yup.string().required("Required"),
  }),
};

export default validationSchema;
