import { useEffect, useState } from "react";
import useLocalStorage from "../../helpers/useLocalStorage";

import mus from "../../sound/merry-christmas.mp3";
let _messageSound = new Audio(
  "https://banhouse-file-storage.s3.ap-southeast-1.amazonaws.com/audio/incoming-message-sound-1.mp3"
);
let _orderSound = new Audio(
  "https://banhouse-file-storage.s3.ap-southeast-1.amazonaws.com/audio/incoming-order-sound-1.wav"
);
var myAudio = new Audio(mus);

export const useSoundState = () => {
  const [audioSetting, setAudioSetting] = useLocalStorage("audioSetting", {
    // ສຽງລະບົບຂາຍໜ້າຮ້ານ
    order: true,
    openTable: true,
    // ສຽງຂໍ້ຄວາມ
    message: true,
    // ສຽງອື່ນໆ
    music: true,
  });
  const [runSound, setRunSound] = useState();

  useEffect(() => {
    if (runSound?.messageSound) {
      setRunSound();
      return messageSound();
    } else if (runSound?.orderSound) {
      setRunSound();
      return orderSound();
    }
  }, [runSound]);

  const messageSound = () => {
    if (audioSetting?.message) {
      _messageSound.play();
    }
  };

  const orderSound = () => {
    if (audioSetting?.order) {
      _orderSound.play();
    }
  };

  // useEffect(() => {
  //   myAudio.addEventListener(
  //     "ended",
  //     function () {
  //       this.currentTime = 0;
  //       this.play();
  //     },
  //     false
  //   );
  //   myAudio.play();
  //   return () => {
  //     myAudio.pause();
  //     myAudio.currentTime = 0;
  //   };
  // }, []);

  return {
    audioSetting,
    setAudioSetting,
    messageSound,
    orderSound,
    runSound,
    setRunSound,
    myAudio
  };
};
