import { useEffect, useState } from "react";
import { Button, Form, Card, Pagination, Table } from "react-bootstrap";
import { getLocalData } from "../../../constants/api";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import { MdAssignmentAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { userGetMany } from "../../../services/therdy/user.service";

const ENV_IMAGE =
  "https://banhouse-storage.s3-ap-southeast-1.amazonaws.com/images/";

export default function UserPage() {
  const navigate = useNavigate();
  // state
  const [pagination, setPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(10);
  const [usersData, setUsersData] = useState<any[]>([]);

  // store
  // const { storeDetail }: any = useStore();

  // useEffect
  useEffect(() => {
    getData();
  }, []);
  // function
  const getData = async () => {
    try {
      const { TOKEN } = await getLocalData();
      const findby = "p=walletId";
      const data = await userGetMany(findby, TOKEN);
      console.log(data);
      setUsersData(data);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <>
      <div className="p-4">
        <Breadcrumb>
          <Breadcrumb.Item>THERDY CO</Breadcrumb.Item>
          <Breadcrumb.Item active>ລາຍການລູກຄ້າ</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs defaultActiveKey="user-list">
          <Tab eventKey="user-list" title="ລາຍການລູກຄ້າທັງໝົດ">
            <div className="flex gap-2 py-2">
              <Form.Control
                style={{ maxWidth: 220 }}
                placeholder="ຄົ້ນຫາລະຫັດບິນ"
              />
              <Button variant="primary">ຄົ້ນຫາ</Button>
            </div>

            <Card border="primary">
              <Card.Header className="flex items-center">
                <div>ລາຍການລູກຄ້າ </div>
                <div className="flex-1" />
                <Button
                  onClick={() => navigate("/banhouse/product/create-one")}
                >
                  <MdAssignmentAdd /> ເພີ່ມລາຍການ
                </Button>
              </Card.Header>
              <Card.Body>
                <Table>
                  <table>
                    <tr>
                      <th className="text-start">#</th>
                      <th className="text-start">ຊື່</th>
                      <th className="text-start">email</th>
                      <th className="text-start">username</th>
                      <th className="text-start">point</th>
                      <th className="text-start">role</th>
                      <th className="text-start">tool</th>
                    </tr>
                    {usersData?.map((value: any, index: any) => (
                      <tr key={index}>
                        <td className="text-start">{index + 1}</td>
                        <td className="text-start">{value?.name}</td>
                        <td className="text-start">{value?.email}</td>
                        <td className="text-start">{value?.username}</td>
                        <td className="text-start">
                          {value?.walletId?.balance}
                        </td>
                        <td className="text-start">{value?.role}</td>
                        <td>
                          <Button variant="outline-primary">Detail</Button>
                        </td>
                      </tr>
                    ))}
                  </table>
                </Table>
              </Card.Body>
              <Card.Footer>
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    bottom: 20,
                  }}
                >
                  <ReactPaginate
                    previousLabel={
                      <span className="glyphicon glyphicon-chevron-left">{`ກ່ອນໜ້າ`}</span>
                    }
                    nextLabel={
                      <span className="glyphicon glyphicon-chevron-right">{`ຕໍ່ໄປ`}</span>
                    }
                    breakLabel={<Pagination.Item disabled>...</Pagination.Item>}
                    breakClassName={"break-me"}
                    pageCount={totalPagination} // Replace with the actual number of pages
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={(e) => {
                      console.log(e);
                      setPagination(e?.selected + 1);
                    }}
                    containerClassName={"pagination justify-content-center"} // Bootstrap class for centering
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                  />
                </div>
              </Card.Footer>
            </Card>
          </Tab>
          <Tab eventKey="history" title="ລູກຄ້າຖືກແບນ"></Tab>
        </Tabs>
      </div>
    </>
  );
}
