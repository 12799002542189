import { Modal, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import UploadImageFormik from "../../UploadImageFormik";
import CustomInput from "../../CustomInput";
import CustomSelect from "../../CustomSelect";
import validationSchema from "../../../utils/validationSchema";
import {
  productPackageCreateOne,
  productPackageGetOne,
  productPackageUpdateOne,
} from "../../../services/therdy/product.service";
import { errorAdd, successAdd } from "../../../helpers/sweetalert";
import { useEffect, useState } from "react";

interface PopUpUpdatePackageProps {
  open: boolean;
  onClose: () => void;
  productPackageId: string;
  callback: (value: any) => void;
}

export default function PopUpUpdatePackage({
  open,
  onClose,
  productPackageId,
  callback,
}: PopUpUpdatePackageProps) {
  const [packageData, setPackageData] = useState<any>({});

  useEffect(() => {
    if (productPackageId) {
      fetchData();
    }
  }, [productPackageId]);

  const fetchData = async () => {
    try {
      const data = await productPackageGetOne(productPackageId, "");
      setPackageData(data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const onSubmit = async (values: any) => {
    const data = await productPackageUpdateOne(
      productPackageId,
      { ...values },
      ""
    );
    callback(data);
    if (data.error) {
      errorAdd("error something");
    } else {
      successAdd("success");
      onClose();
    }
  };

  return (
    <Modal show={open} onHide={onClose} keyboard={false}>
      <Modal.Header>
        <Modal.Title>Update Product Package</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={packageData}
        validationSchema={validationSchema.createPackage}
        onSubmit={onSubmit}
      >
        <Form>
          <Modal.Body>
            <div className="gap-[10px]">
              <UploadImageFormik
                commentText="500x500"
                name="image"
                style={{ width: "200px", height: "200px" }}
              />
              <CustomInput
                require
                label="Package Name"
                name="name"
                type="text"
                placeholder="-- Package Name --"
              />
              <CustomInput
                require
                label="Price"
                name="price"
                type="number"
                placeholder="-- Price --"
              />
              <CustomSelect
                require
                label="Show on"
                name="active"
                options={[
                  {
                    value: "ADMIN",
                    label: "Admin Only",
                  },
                  {
                    value: "VIP",
                    label: "VIP Only",
                  },
                  {
                    value: "PUBLIC",
                    label: "ALL",
                  },
                ]}
              />
              <CustomInput
                label="Cost"
                name="cost"
                type="number"
                placeholder="-- Cost --"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              ບັນທຶກ
            </Button>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
}
