import React, { useState, useMemo } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store";
import { getStore } from "../../services/store";
import { USER_KEY } from "../../constants";
import role from "../../helpers/role";
import { login_service } from "../../services/auth";

// icons
import { FaEye } from "react-icons/fa";
import { IoLogoFacebook } from "react-icons/io";
import { FcGoogle } from "react-icons/fc";

// helpers
import { ErrorSwal, testSwal } from "../../helpers/alertsweet";

// components
import Logo from "../../components/Logo";

type FormData = {
  identifier?: string;
  password?: string;
};

const Login: React.FC = () => {
  const navigate = useNavigate();

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<FormData>({});
  const [hiddenInputPassword, setHiddenInputPassword] = useState(true);

  const { setStoreDetail, setProfile }: any = useStore();

  useMemo(() => {
    console.log("GOOGLE ANALYTICS STARTED");
    const TRACKING_ID = "G-LLZP539QT0";
    // ReactGA.initialize(TRACKING_ID, { debug: true });
  }, []);

  // function
  const handleLogin = () => {
    console.log("handleLogin");
  };

  const authSignUpFacebookPost = async (data: { accessToken: string }) => {
    // Implement Facebook signup functionality
  };

  const _login = async () => {
    setIsLoading(true);
    try {
      const user = await login_service(formData as any);
      if (user.error) throw new Error("-");
      const { defaultPath } = role(user?.data?.role, user?.data);
      if (defaultPath) {
        localStorage.setItem(USER_KEY, JSON.stringify(user));
        localStorage.setItem("BANHOUSE_TOKEN", user.jwt);
        setProfile(user);
        const data = await getStore(user?.data?.storeId);
        setStoreDetail(data);
        document.title = data?.name;

        // ReactGA.send({ hitType: "pageview", title: `${data?.name}` });
        navigate(defaultPath);
      }
    } catch (error) {
      ErrorSwal("ຊື່ຜູ້ໃຊ້ຫຼືລະຫັດບໍ່ຖືກ");
    }
    setIsLoading(false);
  };

  const responseFacebook = async (response: any) => {
    try {
      const _profile = await authSignUpFacebookPost({
        accessToken: response.accessToken!,
      });
      setProfile(_profile);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100dvh",
        width: "100dvw",
        backgroundColor: "gray",
        backgroundImage:
          "repeating-linear-gradient(45deg, #ffffff1a 0px, #ffffff1a 10px, transparent 10px, transparent 20px, #ffffff1a 20px)",
        padding: 10,
      }}
    >
      <div style={{ flex: 1 }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 20,
          width: "100%",
          maxWidth: "500px",
          gap: 10,
          backgroundColor: "#ffffff",
          boxShadow: "1px 0px 10px rgba(0,0,0,0.1)",
        }}
      >
        <div
          style={{
            padding: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Logo style={{ width: 200 }} />
          <div style={{ fontWeight: 700, fontSize: 26 }}>Super Box - Login</div>
        </div>
        <Form.Control
          disabled={isLoading}
          value={formData?.identifier || ""}
          placeholder="Username"
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, identifier: e.target.value }))
          }
        />
        <InputGroup className="mb-3">
          <Form.Control
            type={hiddenInputPassword ? "password" : "text"}
            disabled={isLoading}
            value={formData?.password || ""}
            placeholder="Password"
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, password: e.target.value }))
            }
          />
          <InputGroup.Text>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setHiddenInputPassword((prev) => !prev)}
            >
              <FaEye />
            </div>
          </InputGroup.Text>
        </InputGroup>

        <Button onClick={_login} disabled={isLoading}>
          LOGIN NOW!
        </Button>
        <Button
          variant="outline-primary"
          type="submit"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            fontWeight: "initial",
            paddingLeft: 25,
            fontSize: 12,
            gap: 10,
          }}
          disabled
          onClick={handleLogin}
        >
          <FcGoogle style={{ width: 24, height: 24 }} />
          <span>LOGIN WITH GOOGLE</span>
        </Button>
        <FacebookLogin
          appId="850461839882668"
          fields="name,email,picture"
          scope="public_profile"
          callback={responseFacebook}
          isMobile={false}
          disableMobileRedirect={true}
          render={(renderProps: any) => (
            <Button
              variant="outline-primary"
              type="submit"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                fontWeight: "initial",
                paddingLeft: 25,
                fontSize: 12,
                gap: 10,
              }}
              disabled
              onClick={() => testSwal("ກຳລັງພັດທະນາ")}
            >
              <IoLogoFacebook style={{ width: 24, height: 24 }} />
              <span>LOGIN WITH FACEBOOK</span>
            </Button>
          )}
        />
      </div>
      <div style={{ flex: 2 }} />
      <div
        style={{
          textAlign: "center",
          color: "#ffffff",
          fontSize: 14,
          opacity: 0.7,
        }}
      >
        Copyright © 2024 banhouse.la
      </div>
    </div>
  );
};

export default Login;
