import { Modal, Button, InputGroup } from "react-bootstrap";
import { Formik, Form } from "formik";
import UploadImageFormik from "../../UploadImageFormik";
import CustomInput from "../../CustomInput";
import CustomSelect from "../../CustomSelect";
import validationSchema from "../../../utils/validationSchema";
import {
  productCreateOne,
  productGetOne,
  productUpdateOne,
} from "../../../services/therdy/product.service";
import { errorAdd, successAdd } from "../../../helpers/sweetalert";
import { useEffect, useState } from "react";
import { Product } from "../../../interfaces/Product";
import PayloadInput from "../../PayloadInput";

interface PopUpUpdateProductProps {
  productId: string;
  open: boolean;
  onClose: () => void;
}

export default function PopUpUpdateProduct({
  productId,
  open,
  onClose,
}: PopUpUpdateProductProps) {
  const [productData, setProductData] = useState<any>({});
  const [payloads, setPayloads] = useState<any[]>([]);

  // useEffect
  useEffect(() => {
    getData();
  }, [open]);
  // function
  const getData = async () => {
    if (!productId) return;
    try {
      const data = await productGetOne(productId, "");
      setProductData(data);
      setPayloads(data.payload || []);
    } catch (err) {
      console.log("err", err);
    }
  };

  const onSubmit = async (values: Product) => {
    console.log("values:", values);
    const { error } = await productUpdateOne(productId, { ...values }, "");
    if (error) {
      errorAdd("error something");
    } else {
      successAdd("success");
      onClose();
    }
  };

  const handleAddPayload = () => {
    setPayloads([...payloads, { title: "", value: "" }]);
  };

  const handleDeletePayload = (
    index: number,
    setFieldValue: any,
    values: any
  ) => {
    const newPayloads = values.payload.filter(
      (_: any, i: number) => i !== index
    );
    setFieldValue("payload", newPayloads);
    setPayloads(newPayloads);
  };

  return (
    <Modal show={open} onHide={onClose} keyboard={false}>
      <Modal.Header>
        <Modal.Title>Create Product</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...productData,
          payload: payloads,
        }}
        validationSchema={validationSchema.createProduct}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Modal.Body>
              <div className="gap-[10px]">
                <UploadImageFormik
                  commentText="500x500"
                  name="image"
                  style={{ width: "200px", height: "200px" }}
                />
                <CustomInput
                  require
                  label="Product Name"
                  name="name"
                  type="text"
                  placeholder="-- Package Name --"
                />

                <CustomSelect
                  require
                  label="Show on"
                  name="active"
                  options={[
                    {
                      label: "Admin Only",
                      value: "ADMIN",
                    },
                    {
                      label: "VIP Only",
                      value: "VIP",
                    },
                    {
                      label: "ALL",
                      value: "PUBLIC",
                    },
                  ]}
                />
                <CustomSelect
                  require
                  label="Platform"
                  name="type"
                  options={[
                    {
                      label: "Control By Admin",
                      value: "ADMIN",
                    },
                    {
                      label: "moogold.com",
                      value: "MOOGOLD",
                    },
                  ]}
                />
              </div>
              <label>payload</label>
              <div className="p-2 border">
                {(values.payload || []).map((e: any, i: any) => (
                  <InputGroup
                    key={i}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 80px",
                    }}
                  >
                    <PayloadInput
                      require
                      label={"Payload_" + (i + 1)}
                      name={`payload[${i}].title`}
                      type="text"
                      placeholder="-- title --"
                    />
                    <PayloadInput
                      label={"-"}
                      name={`payload[${i}].value`}
                      type="text"
                      placeholder="-- value --"
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>-</label>
                      <Button
                        onClick={() =>
                          handleDeletePayload(i, setFieldValue, values)
                        }
                      >
                        Delete
                      </Button>
                    </div>
                  </InputGroup>
                ))}
              </div>
              <Button
                variant="outline-primary"
                style={{ width: "100%" }}
                onClick={() => {
                  const newPayloads = [
                    ...(values.payload || []),
                    { title: "", value: "" },
                  ];
                  setFieldValue("payload", newPayloads);
                  setPayloads(newPayloads);
                }}
              >
                Add Payload
              </Button>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                ບັນທຶກ
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
