import axios from "axios";
import { WarningNetworkFailure } from "../../helpers/alertsweet";
import { Product } from "../../interfaces/Product";

const END_POINT_SEVER =
  process.env.REACT_APP_THERDY_API_URL || "https://minion-api.nonlub.com";

export const productPackageGetMany = async (
  productId: string,
  findby: string,
  token: string
) => {
  try {
    const url =
      END_POINT_SEVER + "/product/package/get/" + productId + "?" + findby;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const productPackageGetOne = async (_id: string, token: string) => {
  try {
    const url = END_POINT_SEVER + "/product-package/get-one/" + _id;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const productPackageUpdateOne = async (
  _id: string,
  body: Product,
  token: string
) => {
  try {
    const url = END_POINT_SEVER + "/product-package/update-one";
    const res = await axios.put(
      url,
      { id: _id, data: body },
      {
        headers: { authorization: "Bearer " + token },
      }
    );
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const productPackageDeleteOne = async (
  productPackageId: string,
  token: string
) => {
  try {
    const url = END_POINT_SEVER + "/product-package/delete-one";
    const res = await axios.post(
      url,
      {
        productPackageId,
      },
      {
        headers: { authorization: "Bearer " + token },
      }
    );
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};
// @ts-ignore
export const productPackageCreateOne = async (body: any, token: string) => {
  try {
    const url = END_POINT_SEVER + "/product-package/create-one";
    const res = await axios.post(url, body, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error) {
    // @ts-ignore
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    // @ts-ignore
    return { error: true, message: error.message };
  }
};
// @ts-ignore
export const productGetMany = async (findby, token) => {
  try {
    const url = END_POINT_SEVER + "/product/admin/get-many?" + findby;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error) {
    // @ts-ignore
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    // @ts-ignore
    return { error: true, message: error.message };
  }
};

export const productGetCount = async (findby: string, token: string) => {
  try {
    const url = END_POINT_SEVER + "/product/get-count?" + findby;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const productGetOne = async (_id: string, token: string) => {
  try {
    const url = END_POINT_SEVER + "/product/get-one/" + _id;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const productUpdateOne = async (
  _id: string,
  body: Product,
  token: string
) => {
  try {
    const url = END_POINT_SEVER + "/product/update-one";
    const res = await axios.put(
      url,
      { id: _id, data: body },
      {
        headers: { authorization: "Bearer " + token },
      }
    );
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const productUpdateMany = async (body: any, token: string) => {
  try {
    const url = END_POINT_SEVER + "/product/update-many";
    const res = await axios.put(url, body, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const productCreateOne = async (body: Product, token: string) => {
  try {
    const url = END_POINT_SEVER + "/product/create-one";
    const res = await axios.post(url, body, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: Error | unknown) {
    let message = "Unknown Error";
    if (error instanceof Error) message = error.message;
    if (message === "Network Error") {
      WarningNetworkFailure();
    }
    return { error: true, message: message };
  }
};

export const productCreateMany = async (body: any, token: string) => {
  try {
    const url = END_POINT_SEVER + "/product/create-many";
    const res = await axios.post(url, body, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const productDeleteOne = async (_id: string, token: string) => {
  try {
    const url = END_POINT_SEVER + "/product/delete-one/" + _id;
    const res = await axios.delete(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};
