import React, { useEffect } from "react";
import { useField, FieldHookConfig } from "formik";
import { Form } from "react-bootstrap";

const PayloadInput = ({ label, require, ...props }: any) => {
  const [field, meta] = useField(props);

  return (
    <div className="form-group">
      <label htmlFor={props.id || props.name}>
        {label} {!!require && <a style={{ color: "#F91B1B" }}>*</a>}
      </label>
      <Form.Control {...props} {...field} isInvalid={!!meta.error} />
      {meta.error ? (
        <div style={{ color: "red", marginLeft: 5, fontSize: 12 }}>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default PayloadInput;
