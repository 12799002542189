import React from "react";
import Navbar from "./Navbar";
import Sidenav from "./SideNav";
import { Outlet, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FaSearch, FaUser } from "react-icons/fa"; // Import icons
import { BiSolidReport } from "react-icons/bi";
import { FaBoxOpen } from "react-icons/fa";
import { FaListOl } from "react-icons/fa6";
import { IoSettingsSharp } from "react-icons/io5";
interface MainLayoutProps {
  children?: React.ReactNode;
}

const FooterBar = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full bg-white shadow-sm grid grid-cols-4 p-2 gap-2 md:hidden">
      <Button
        className="flex flex-col gap-1 text-sm items-center justify-center"
        onClick={() => navigate("/therdy/report")}
      >
        <BiSolidReport className="h-[18px] w-[18px]" />
        <span>ລາຍງານ</span>
      </Button>
      <Button
        className="flex flex-col gap-1 text-sm items-center justify-center"
        onClick={() => navigate("/therdy/product")}
      >
        <FaBoxOpen className="h-[18px] w-[18px]" />
        <span>ສິນຄ້າ</span>
      </Button>
      <Button
        className="flex flex-col gap-1 text-sm items-center justify-center"
        onClick={() => navigate("/therdy/bill")}
      >
        <FaListOl className="h-[18px] w-[18px]" />
        <span>ອໍເດີ</span>
      </Button>
      <Button
        className="flex flex-col gap-1 text-sm items-center justify-center"
        onClick={() => navigate("/therdy/user")}
      >
        <IoSettingsSharp className="h-[18px] w-[18px]" />
        <span>ຕັ້ງຄ້າ</span>
      </Button>
    </div>
  );
};

export default function MainLayout({ children }: MainLayoutProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-[auto_1fr] w-full h-screen">
      <div className="overflow-auto hidden md:block">
        <Sidenav />
      </div>
      <div className="w-full overflow-auto overflow-y-scroll overflow-x-hidden relative flex flex-col h-full min-h-full">
        <Navbar />
        <div className="flex-1">
          <Outlet />
        </div>
      </div>
      <FooterBar />
    </div>
  );
}
