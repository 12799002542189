// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Card, Button, Form, ButtonGroup, Breadcrumb } from "react-bootstrap";
import * as _ from "lodash";
import { COLOR_APP, END_POINT } from "../../constants";
import ButtonDropdown from "../../components/button/ButtonDropdown";
import { BsArrowCounterclockwise, BsInfoCircle } from "react-icons/bs";
import ReportChartMonth from "../../components/report_chart/ReportChartMonth";
import ReportChartDayOfWeek from "../../components/report_chart/ReportChartDayOfWeek";
import PopUpSetStartAndEndDate from "../../components/popup/PopUpSetStartAndEndDate";
import moment from "moment";
import { useStore } from "../../store";
import { getReports } from "../../services/report";
import "moment/locale/lo";
import StarComponent from "../../components/StarComponent";
import { AiFillPrinter } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../../components/Loading";
import { formatToTwoDecimalPlaces } from "../../helpers";

export default function ReportReviewPage() {
  const navigate = useNavigate();
  // state
  const [loading, setLoading] = useState(true);
  const [loadingCount, setLoadingCount] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [salesInformationReport, setSalesInformationReport] = useState();
  const [userReport, setUserReport] = useState();
  const [menuReport, setMenuReport] = useState();
  const [categoryReport, setCategoryReport] = useState();
  const [moneyReport, setMoneyReport] = useState();
  const [promotionReport, setPromotionReport] = useState();
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [startTime, setStartTime] = useState("00:00:00");
  const [endTime, setEndTime] = useState("23:59:59");
  const [popup, setPopup] = useState();
  const [stores, setStores] = useState();
  const [storeCount, setStoreCount] = useState();
  const [reviewCount, setReviewCount] = useState();
  const [reviewCountUp3, setReviewCountUp3] = useState();
  const [reviewCountDown3, setReviewCountDown3] = useState();

  // provider
  const { storeDetail } = useStore();

  // useEffect
  useEffect(() => {
    setLoading(true);
    setLoadingCount(0);
    getStoresData();
    getStoreCountData();
    getStoreReviewCountData();

    // getStoreReviewCountUp3Data();
    // getStoreReviewCountdown3Data();
  }, [endDate, startDate, endTime, startTime]);

  useEffect(() => {
    if (loadingCount === 3) {
      setLoading(false);
    }
  }, [loadingCount]);

  // function
  const getStoresData = async () => {
    try {
      const _res = await axios.get(
        END_POINT + "/store/get-many?storeId=" + storeDetail?._id
      );

      const responData = [];
      for (let item of _res.data) {
        let _sum = 0;
        let _starLength = 0;
        for (let _sub of item.substars) {
          let allStar = _sub.averageStars;
          _sum = _sum + allStar;
          if (_starLength < _sub.count) {
            _starLength = _sub.count;
          }
        }
        if (isNaN(_sum)) {
          _sum = 0;
        }
        _sum = _sum / item.substars.length;
        responData.push({ ...item, star: _sum, starLength: _starLength });
      }

      console.log("responData", responData);
      if (_res.status >= 300) throw new Error("");
      setStores(responData);
      setLoadingCount((prev) => prev + 1);
    } catch (err) {
      console.log("err:", err);
    }
  };
  const getStoreCountData = async () => {
    try {
      const _res = await axios.get(
        END_POINT + "/store/get-count?storeId=" + storeDetail?._id
      );
      if (_res.status >= 300) throw new Error("");
      setStoreCount(_res.data.count);
      setLoadingCount((prev) => prev + 1);
    } catch (err) {
      console.log("err:", err);
    }
  };
  const getStoreReviewCountData = async () => {
    try {
      const _res = await axios.get(
        END_POINT +
          "/review/get-count-by-store-main?storeId=" +
          storeDetail?._id
      );
      if (_res.status >= 300) throw new Error("");
      setReviewCount(_res.data.count);
      setLoadingCount((prev) => prev + 1);
    } catch (err) {
      console.log("err:", err);
    }
  };
  const getStoreReviewCountUp3Data = async () => {
    try {
      const _res = await axios.get(
        END_POINT +
          "/review/get-count-by-store-main?storeId=" +
          storeDetail?._id +
          "&totalStar=" +
          { $gt: 3 }
      );
      if (_res.status >= 300) throw new Error("");
      setReviewCount(_res.data.count);
      setLoadingCount((prev) => prev + 1);
    } catch (err) {
      console.log("err:", err);
    }
  };
  const getStoreReviewCountdown3Data = async () => {
    try {
      const _res = await axios.get(
        END_POINT +
          "/review/get-count-by-store-main?storeId=" +
          storeDetail?._id +
          "&totalStar=" +
          { $lt: 3 }
      );
      if (_res.status >= 300) throw new Error("");
      setReviewCountDown3(_res.data.count);
    } catch (err) {
      console.log("err:", err);
    }
  };

  return (
    <div className="p-2 md:p-4 h-screen">
      <div hidden={!loading}>
        <Loading />
      </div>
      <Breadcrumb>
        <Breadcrumb.Item>ລາຍງານ</Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍງານລູກຄ້າລີວິວ</Breadcrumb.Item>
      </Breadcrumb>
      <div className="mb-2">
        <Button onClick={() => navigate("/store/all-store")}>
          <AiFillPrinter /> ເພີ່ມຮ້ານ
        </Button>
      </div>
      <div className="grid gap-2 mb-2 grid-cols-2 md:grid-cols-4">
        <Card bg="primary" text="white">
          <Card.Header>ຈຳນວນການລີວິວທັງໝົດ</Card.Header>
          <Card.Body
            style={{
              backgroundImage:
                "repeating-linear-gradient(45deg, #ffffff1a 0px, #ffffff1a 10px, transparent 10px, transparent 20px, #ffffff1a 20px)",
            }}
          >
            <Card.Title>{reviewCount || 0} ຄັ້ງ</Card.Title>
          </Card.Body>
        </Card>
        <Card bg="primary" text="white">
          <Card.Header>ຈຳນວນຮ້ານ</Card.Header>
          <Card.Body
            style={{
              backgroundImage:
                "repeating-linear-gradient(45deg, #ffffff1a 0px, #ffffff1a 10px, transparent 10px, transparent 20px, #ffffff1a 20px)",
            }}
          >
            <Card.Title>{storeCount || 0} ຮ້ານ</Card.Title>
          </Card.Body>
        </Card>
      </div>
      <Breadcrumb>
        <Breadcrumb.Item active>ລາຍການຮ້ານ</Breadcrumb.Item>
      </Breadcrumb>

      <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
        {stores?.map((e) => (
          <Card border="primary">
            <Card.Header>{e?.name}</Card.Header>
            <Card.Body>
              <div
                style={{ display: "flex", justifyContent: "center", gap: 20 }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontSize: 28, fontWeight: 700 }}>
                    {e?.star ? formatToTwoDecimalPlaces(e?.star) : 0}
                  </div>
                  <div>
                    <StarComponent
                      star={e?.star ? formatToTwoDecimalPlaces(e?.star) : 0}
                      onChangeStar={() => {}}
                      rootStyle={{ gap: 2 }}
                      starStyle={{ width: 10, height: 10 }}
                    />
                  </div>
                  <div style={{ fontSize: 14, textAlign: "center" }}>
                    {e?.starLength} ຄັ້ງ
                  </div>
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Button
                style={{ width: "100%" }}
                onClick={() =>
                  navigate("/report/review-report/store/" + e?._id)
                }
              >
                ເບິ່ງລີວິວ
              </Button>
            </Card.Footer>
          </Card>
        ))}
      </div>
      <PopUpSetStartAndEndDate
        open={popup?.popupfiltter}
        onClose={() => setPopup()}
        startDate={startDate}
        setStartDate={setStartDate}
        setStartTime={setStartTime}
        startTime={startTime}
        setEndDate={setEndDate}
        setEndTime={setEndTime}
        endTime={endTime}
        endDate={endDate}
      />
    </div>
  );
}

function ReportCard({ title, chart }) {
  return (
    <Card border="primary" style={{ margin: 0 }}>
      <Card.Header
        style={{
          backgroundColor: COLOR_APP,
          color: "#fff",
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        {title} <BsInfoCircle />
      </Card.Header>
      <Card.Body>
        {/* <Card.Title>Special title treatment</Card.Title>
        <Card.Text>
          With supporting text below as a natural lead-in to additional content.
        </Card.Text> */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 10,
            padding: 10,
          }}
        >
          <ButtonDropdown variant="outline-primary">
            <option>ຍອດຈຳນວນ</option>
            <option>ຍອດເງິນ</option>
          </ButtonDropdown>
          <Button variant="outline-primary">ເລືອກສິນຄ້າ 1 ລາຍການ</Button>
          <ButtonGroup aria-label="Basic example">
            <Button variant="outline-primary">{"<<"}</Button>
            <Button variant="outline-primary">01/03/2023 ~ 31/03/2023</Button>
            <Button variant="outline-primary">{">>"}</Button>
          </ButtonGroup>
          <div>ປຽບທຽບກັບ</div>
          <ButtonDropdown variant="outline-primary">
            <option value={"test"}>ເດືອນແລ້ວ</option>
            <option value={"test2"}>ຕົ້ນປີ</option>
            <option value={"test3"}>01/03/2023 ~ 31/03/2023</option>
          </ButtonDropdown>
          <Button variant="outline-primary">
            <BsArrowCounterclockwise />
          </Button>
        </div>
        <div>{chart}</div>
      </Card.Body>
    </Card>
  );
}

function DashboardBillMonthCard({ title }) {
  //state
  const [series, setSeries] = useState([]);
  const [month1, setMonth1] = useState();
  const [month2, setMonth2] = useState();

  const [reportDataMonth1, setReportDataMonth1] = useState([]);
  const [reportDataMonth2, setReportDataMonth2] = useState([]);

  // provider
  const { storeDetail } = useStore();

  // useEffect
  useEffect(() => {
    if (month1) {
      getReportDataMonth1();
    }
  }, [month1]);
  useEffect(() => {
    if (month2) {
      getReportDataMonth2();
    }
  }, [month2]);

  useEffect(() => {
    let _series = [];
    if (reportDataMonth1) {
      let _month1 = {
        name: month1,
        data: [...new Array(31)].map((e, i) => {
          const data = reportDataMonth1.find((item) => {
            if (
              parseInt(moment(item?.date, "YYYY-MM-DD").format("DD")) ==
              i + 1
            ) {
              return true;
            }
            return false;
          });
          console.log("first", data);
          if (data) {
            return data?.billAmount;
          } else {
            return null;
          }
        }),
      };
      _series.push(_month1);
    }
    if (reportDataMonth2) {
      let _month2 = {
        name: month2,
        data: [...new Array(31)].map((e, i) => {
          const data = reportDataMonth2.find((item) => {
            if (parseInt(moment(item?.date).format("DD")) === i + 1) {
              return true;
            }
            return false;
          });
          if (data) {
            return data?.billAmount;
          } else {
            return null;
          }
        }),
      };
      _series.push(_month2);
    }

    setSeries(_series);
  }, [reportDataMonth1, reportDataMonth2]);

  // function
  const getReportDataMonth1 = async () => {
    const startDate = moment(month1, "YYYY-MM")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment(month1, "YYYY-MM")
      .endOf("month")
      .format("YYYY-MM-DD");
    const endTime = "00:00:00";
    const startTime = "23:59:59";

    const findBy = `?startDate=${startDate}&endDate=${endDate}&endTime=${endTime}&startTime=${startTime}`;
    const data = await getReports(storeDetail?._id, findBy);
    setReportDataMonth1(data);
  };
  const getReportDataMonth2 = async () => {
    const startDate = moment(month2, "YYYY-MM")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment(month2, "YYYY-MM")
      .endOf("month")
      .format("YYYY-MM-DD");
    const endTime = "00:00:00";
    const startTime = "23:59:59";
    const findBy = `?startDate=${startDate}&endDate=${endDate}&endTime=${endTime}&startTime=${startTime}`;
    const data = await getReports(storeDetail?._id, findBy);
    setReportDataMonth2(data);
  };

  return (
    <Card border="primary" style={{ margin: 0 }}>
      <Card.Header
        style={{
          backgroundColor: COLOR_APP,
          color: "#fff",
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        {title} <BsInfoCircle />
      </Card.Header>
      <Card.Body>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 10,
            padding: 10,
          }}
        >
          <ButtonDropdown variant="outline-primary">
            <option>ຍອດຈຳນວນ</option>
            <option>ຍອດເງິນ</option>
          </ButtonDropdown>
          <Button variant="outline-primary">ເລືອກສິນຄ້າ 1 ລາຍການ</Button>
          <ButtonGroup aria-label="Basic example">
            <Button variant="outline-primary">{"<<"}</Button>
            <Form.Control
              type="month"
              variant="outline-primary"
              style={{ borderRadius: 0 }}
              value={month1}
              onChange={(e) => {
                console.log(e.target.value);
                setMonth1(e.target.value);
              }}
            />
            <Button variant="outline-primary">{">>"}</Button>
          </ButtonGroup>
          <div>ປຽບທຽບກັບ</div>
          <ButtonGroup aria-label="Basic example">
            <Button variant="outline-primary">{"<<"}</Button>
            <Form.Control
              type="month"
              variant="outline-primary"
              style={{ borderRadius: 0 }}
              value={month2}
              onChange={(e) => {
                setMonth2(e.target.value);
              }}
            />
            <Button variant="outline-primary">{">>"}</Button>
          </ButtonGroup>
          <Button variant="outline-primary">
            <BsArrowCounterclockwise />
          </Button>
        </div>
        <div>
          <ReportChartMonth series={series} />
        </div>
      </Card.Body>
    </Card>
  );
}

function DashboardBillWeekCard({ title }) {
  //state
  const [series, setSeries] = useState([]);
  const [month1, setMonth1] = useState();
  const [month2, setMonth2] = useState();

  const [reportDataMonth1, setReportDataMonth1] = useState([]);
  const [reportDataMonth2, setReportDataMonth2] = useState([]);

  // provider
  const { storeDetail } = useStore();

  // useEffect
  useEffect(() => {
    if (month1) {
      getReportDataMonth1();
    }
  }, [month1]);
  useEffect(() => {
    if (month2) {
      getReportDataMonth2();
    }
  }, [month2]);

  useEffect(() => {
    let _series = [];
    if (reportDataMonth1) {
      let _month1 = {
        name: month1,
        data: ["ຈັນ", "ອັງຄານ", "ພຸດ", "ພະຫັດ", "ສຸກ", "ເສົາ", "ອາທິດ"].map(
          (e, i) => {
            const data = reportDataMonth1.filter((item) => {
              console.log(moment(item?.date, "YYYY-MM-DD").format("dddd"));
              if (moment(item?.date, "YYYY-MM-DD").format("dddd") === e) {
                return true;
              }
              return false;
            });
            const _sum = _.sumBy(data, function (o) {
              return o.billAmount;
            });
            if (_sum) {
              return _sum;
            } else {
              return null;
            }
          }
        ),
      };
      _series.push(_month1);
    }
    if (reportDataMonth2) {
      let _month2 = {
        name: month2,
        data: ["ຈັນ", "ອັງຄານ", "ພຸດ", "ພະຫັດ", "ສຸກ", "ເສົາ", "ອາທິດ"].map(
          (e, i) => {
            const data = reportDataMonth2.filter((item) => {
              console.log(moment(item?.date, "YYYY-MM-DD").format("dddd"));
              if (moment(item?.date, "YYYY-MM-DD").format("dddd") === e) {
                return true;
              }
              return false;
            });
            const _sum = _.sumBy(data, function (o) {
              return o.billAmount;
            });
            if (_sum) {
              return _sum;
            } else {
              return null;
            }
          }
        ),
      };
      _series.push(_month2);
    }

    setSeries(_series);
  }, [reportDataMonth1, reportDataMonth2]);

  // function
  const getReportDataMonth1 = async () => {
    const startDate = moment(month1, "YYYY-MM")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment(month1, "YYYY-MM")
      .endOf("month")
      .format("YYYY-MM-DD");
    const endTime = "00:00:00";
    const startTime = "23:59:59";

    const findBy = `?startDate=${startDate}&endDate=${endDate}&endTime=${endTime}&startTime=${startTime}`;
    const data = await getReports(storeDetail?._id, findBy);
    setReportDataMonth1(data);
  };
  const getReportDataMonth2 = async () => {
    const startDate = moment(month2, "YYYY-MM")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment(month2, "YYYY-MM")
      .endOf("month")
      .format("YYYY-MM-DD");
    const endTime = "00:00:00";
    const startTime = "23:59:59";
    const findBy = `?startDate=${startDate}&endDate=${endDate}&endTime=${endTime}&startTime=${startTime}`;
    const data = await getReports(storeDetail?._id, findBy);
    setReportDataMonth2(data);
  };

  return (
    <Card border="primary" style={{ margin: 0 }}>
      <Card.Header
        style={{
          backgroundColor: COLOR_APP,
          color: "#fff",
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        {title} <BsInfoCircle />
      </Card.Header>
      <Card.Body>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 10,
            padding: 10,
          }}
        >
          <ButtonDropdown variant="outline-primary">
            <option>ຍອດຈຳນວນ</option>
            <option>ຍອດເງິນ</option>
          </ButtonDropdown>
          <Button variant="outline-primary">ເລືອກສິນຄ້າ 1 ລາຍການ</Button>
          <ButtonGroup aria-label="Basic example">
            <Button variant="outline-primary">{"<<"}</Button>
            <Form.Control
              type="month"
              variant="outline-primary"
              style={{ borderRadius: 0 }}
              value={month1}
              onChange={(e) => {
                console.log(e.target.value);
                setMonth1(e.target.value);
              }}
            />
            <Button variant="outline-primary">{">>"}</Button>
          </ButtonGroup>
          <div>ປຽບທຽບກັບ</div>
          <ButtonGroup aria-label="Basic example">
            <Button variant="outline-primary">{"<<"}</Button>
            <Form.Control
              type="month"
              variant="outline-primary"
              style={{ borderRadius: 0 }}
              value={month2}
              onChange={(e) => {
                setMonth2(e.target.value);
              }}
            />
            <Button variant="outline-primary">{">>"}</Button>
          </ButtonGroup>
          <Button variant="outline-primary">
            <BsArrowCounterclockwise />
          </Button>
        </div>
        <div>{/* <ReportChartDayOfWeek series={series} /> */}</div>
      </Card.Body>
    </Card>
  );
}
