import React, { useEffect, useState } from "react";
import { Button, Form, Card, Pagination } from "react-bootstrap";
import { getLocalData } from "../../../constants/api";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import {
  productGetCount,
  productGetMany,
} from "../../../services/therdy/product.service";
import { URL_PHOTO_AW3 } from "../../../constants";
import PopUpAddProduct from "../../../components/popup/therdy/PopUpAddProduct";
import ConvertActive from "../../../helpers/ConvertActive";
import ConvertType from "../../../helpers/ConvertType";
import { useDebounce } from "../../../hooks/useDebounce"; // Import the custom hook

export default function ProductPage() {
  const navigate = useNavigate();
  // state
  const [pagination, setPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(1);
  const [popup, setPopup] = useState<any>({});
  const [filters, setFilters] = useState({
    isAdmin: false,
    isVIP: false,
    isPublic: false,
    typeAdmin: false,
    typeMoogold: false,
    typeCodashop: false,
  });
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 200); // Add debounced search state

  // store
  // const { storeDetail }: any = useStore();

  // function
  const { data: usersData = [], refetch } = useQuery(
    ["products", pagination, filters, debouncedSearch],
    async () => {
      const { TOKEN } = await getLocalData();
      const limitPerPage = 50;
      const skip = (pagination - 1) * limitPerPage;
      let findby = `skip=${skip}&limit=${limitPerPage}`;

      if (filters.isAdmin || filters.isVIP || filters.isPublic) {
        let activeFilter = [];
        if (filters.isAdmin) activeFilter.push("ADMIN");
        if (filters.isVIP) activeFilter.push("VIP");
        if (filters.isPublic) activeFilter.push("PUBLIC");
        findby += "&active=" + activeFilter.join("||");
      }
      if (filters.typeAdmin || filters.typeMoogold || filters.typeCodashop) {
        let typeFilter = [];
        if (filters.typeAdmin) typeFilter.push("ADMIN");
        if (filters.typeMoogold) typeFilter.push("MOOGOLD");
        if (filters.typeCodashop) typeFilter.push("CODASHOP");
        findby += "&type=" + typeFilter.join("||");
      }
      if (debouncedSearch) {
        findby += `&search=${debouncedSearch}`;
      }

      return await productGetMany(findby, TOKEN);
    },
    {
      keepPreviousData: true,
    }
  );

  useQuery(
    ["totalData", filters, debouncedSearch],
    async () => {
      let findby = "";

      if (filters.isAdmin || filters.isVIP || filters.isPublic) {
        let activeFilter = [];
        if (filters.isAdmin) activeFilter.push("ADMIN");
        if (filters.isVIP) activeFilter.push("VIP");
        if (filters.isPublic) activeFilter.push("PUBLIC");
        findby += "&active=" + activeFilter.join("||");
      }
      if (filters.typeAdmin || filters.typeMoogold || filters.typeCodashop) {
        let typeFilter = [];
        if (filters.typeAdmin) typeFilter.push("ADMIN");
        if (filters.typeMoogold) typeFilter.push("MOOGOLD");
        if (filters.typeCodashop) typeFilter.push("CODASHOP");
        findby += "&type=" + typeFilter.join("||");
      }
      if (debouncedSearch) {
        findby += `&search=${debouncedSearch}`;
      }
      const data = await productGetCount(findby, "");
      return data;
    },
    {
      onSuccess: (data) => {
        if (data.count) {
          const totalPages = Math.ceil(data.count / 50);
          setTotalPagination(totalPages);
        }
      },
    }
  );

  const handleFilterChange = (filterName: string, value: boolean) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  return (
    <React.Fragment>
      <div className="p-2 md:p-4">
        <Breadcrumb>
          <Breadcrumb.Item>THERDY CO</Breadcrumb.Item>
          <Breadcrumb.Item active>ລາຍການສິນຄ້າ</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="p-2 mb-2">
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <Form.Control
                style={{ maxWidth: 220 }}
                placeholder="ຄົ້ນຫາ"
                onChange={(e) => setSearch(e.target.value)} // No change needed here
              />
            </div>
            <div className="flex gap-2 flex-wrap">
              <Form.Switch
                id={`isAdmin`}
                label={`ສຳຫຼັບ ADMIN`}
                checked={filters.isAdmin}
                onChange={(value) =>
                  handleFilterChange("isAdmin", value.target.checked)
                }
              />
              <Form.Switch
                id={`isVIP`}
                label={`ສຳຫຼັບ VIP`}
                checked={filters.isVIP}
                onChange={(value) =>
                  handleFilterChange("isVIP", value.target.checked)
                }
              />
              <Form.Switch
                id={`isPublic`}
                label={`ສຳຫຼັບ PUBLIC`}
                checked={filters.isPublic}
                onChange={(value) =>
                  handleFilterChange("isPublic", value.target.checked)
                }
              />
              <Form.Switch
                id={`typeAdmin`}
                label={`ປະເພດ ADMIN`}
                checked={filters.typeAdmin}
                onChange={(value) =>
                  handleFilterChange("typeAdmin", value.target.checked)
                }
              />
              <Form.Switch
                id={`typeMoogold`}
                label={`ປະເພດ MOOGOLD`}
                checked={filters.typeMoogold}
                onChange={(value) =>
                  handleFilterChange("typeMoogold", value.target.checked)
                }
              />
              <Form.Switch
                id={`typeCodashop`}
                label={`ປະເພດ CODASHOP`}
                checked={filters.typeCodashop}
                onChange={(value) =>
                  handleFilterChange("typeCodashop", value.target.checked)
                }
              />
            </div>
          </div>
        </Card>
        <Card className="p-2 mb-2">
          <div className="flex gap-2 justify-end">
            <Button
              variant="primary"
              onClick={() => setPopup({ PopUpAddProduct: true })}
            >
              ເລືອກເກມ
            </Button>
            <Button
              variant="primary"
              onClick={() => setPopup({ PopUpAddProduct: true })}
            >
              ເພີ່ມລາຍການ
            </Button>
          </div>
        </Card>
        <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 gap-2">
          {usersData?.map((value: any, index: any) => (
            <Card
              onClick={() =>
                navigate("/therdy/product/detail?productId=" + value?._id)
              }
              key={value?._id}
            >
              <div className="aspect-square p-2">
                <div className="rounded-lg overflow-hidden">
                  <img
                    src={
                      value?.image
                        ? URL_PHOTO_AW3 + value?.image
                        : "/images/profile.png"
                    }
                    alt={value?.image}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
              <div className="pl-2 text-xs">{value?.name ?? "--"}</div>
              <div></div>
              <div className="flex flex-col gap-2 p-2 justify-end h-full">
                <ConvertType text={value?.type ?? "--"} />
                <ConvertActive text={value?.active ?? "--"} />
              </div>
            </Card>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            bottom: 20,
            paddingTop: 20,
          }}
        >
          <ReactPaginate
            previousLabel={
              <span className="glyphicon glyphicon-chevron-left">{`ກ່ອນໜ້າ`}</span>
            }
            nextLabel={
              <span className="glyphicon glyphicon-chevron-right">{`ຕໍ່ໄປ`}</span>
            }
            breakLabel={<Pagination.Item disabled>...</Pagination.Item>}
            breakClassName={"break-me"}
            pageCount={totalPagination} // Replace with the actual number of pages
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={(e) => {
              console.log(e);
              setPagination(e?.selected + 1);
            }}
            containerClassName={"pagination justify-content-center"} // Bootstrap class for centering
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
          />
        </div>
      </div>
      <PopUpAddProduct
        open={popup?.PopUpAddProduct}
        onClose={() => {
          setPopup({});
        }}
      />
    </React.Fragment>
  );
}
