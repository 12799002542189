import { Modal, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import UploadImageFormik from "../../UploadImageFormik";
import CustomInput from "../../CustomInput";
import CustomSelect from "../../CustomSelect";
import validationSchema from "../../../utils/validationSchema";
import {
  productCreateOne,
  productGetOne,
  productUpdateOne,
} from "../../../services/therdy/product.service";
import { errorAdd, successAdd } from "../../../helpers/sweetalert";
import { useEffect, useState } from "react";
import { Product } from "../../../interfaces/Product";
import { Order } from "../../../interfaces/Order";
import {
  orderGetOne,
  orderUpdateOne,
} from "../../../services/therdy/order.service";

interface PopUpOrderProps {
  orderId: string;
  open: boolean;
  onClose: () => void;
  callback: (data: any) => void;
}

export default function PopUpOrder({
  orderId,
  open,
  onClose,
  callback,
}: PopUpOrderProps) {
  const [productData, setProductData] = useState<any>({});

  // useEffect
  useEffect(() => {
    getData();
  }, [open]);
  // function
  const getData = async () => {
    if (!orderId) return;
    try {
      const data = await orderGetOne(orderId, "");
      setProductData(data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const onSubmit = async (values: Order) => {
    try {
      const data = await orderUpdateOne(orderId, values, "");
      successAdd("success");
      callback(data);
      onClose();
    } catch (error) {
      errorAdd("error something");
    }
  };
  return (
    <Modal show={open} onHide={onClose} keyboard={false}>
      <Modal.Header>
        <Modal.Title>Update Order</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...productData,
        }}
        validationSchema={validationSchema.updateOrder}
        onSubmit={onSubmit}
      >
        <Form>
          <Modal.Body>
            <div className="gap-[10px]">
              <CustomSelect
                require
                label="Status"
                name="status"
                options={[
                  {
                    label: "ສຳເລັດ",
                    value: "SUCCESS",
                  },
                  {
                    label: "ສ້າງໃໝ່",
                    value: "CREATED",
                  },
                  {
                    label: "ກຳລັງດຳເນີນການ",
                    value: "IN_QUEUE",
                  },
                  {
                    label: "ຍົກເລີກ",
                    value: "FAILED",
                  },
                ]}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              ບັນທຶກ
            </Button>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
}
