import React from "react";
// import ReactGA from 'react-ga4';
import Routes from "./routes";
import { StateProvider } from "./store";
import { ToastContainer /* toast */ } from "react-toastify";

// import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();
function App() {
  return (
    <StateProvider>
      <QueryClientProvider client={queryClient}>
        <Routes />
        <ToastContainer />
      </QueryClientProvider>
    </StateProvider>
  );
}
export default App;
