import React from "react";
import { Card } from "react-bootstrap";
import ReportChartDayOfWeek from "../../../components/report_chart/ReportChartDayOfWeek";

type Props = {};

function DashboardBanhouse({}: Props) {
  return (
    <div style={{ height: "100%" }}>
      <div
        className="grid gap-10 p-10 
    md:grid-cols-4 
    sm:grid-cols-2 
    xs:grid-cols-1"
      >
        <Card text="primary" style={{ gridColumn: "span 2" }}>
          <Card.Header>ຍອດເງິນທັງໝົດ</Card.Header>
          <Card.Body>
            {/* <ReportChartDayOfWeek /> */}
          </Card.Body>
        </Card>
        <Card text="primary" style={{ gridColumn: "span 2" }}>
          <Card.Header>ຍອດເງິນທັງໝົດ</Card.Header>
          <Card.Body>
            {/* <ReportChartDayOfWeek /> */}
          </Card.Body>
        </Card>
        {[...new Array(10)].map((e) => (
          <Card>
            <Card.Header>ຈຳນວນການລີວິວທັງໝົດ</Card.Header>
            <Card.Body>
              <Card.Title>0 ຄັ້ງ</Card.Title>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default DashboardBanhouse;
