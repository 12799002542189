import { COLOR_APP } from "../../constants";

import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { converMoney } from "../../helpers/converMoney";

type ReportData = {
  xaxis: string;
  data: number;
};
const ReportChartDayOfWeek = ({ data }: { data: ReportData[] }) => {
  // state
  const [state, setState] = useState({
    series: [
      {
        data: data.map((item) => item.data),
      },
    ],
    options: {
      chart: {
        type: "bar" as "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },

      colors: [COLOR_APP, "gray"],
      xaxis: {
        categories: data.map((item) => item.xaxis),
      },
      yaxis: {
        title: {
          text: "ຈຳນວນ",
        },
      },
      fill: {
        opacity: 1,
      },
    },
  });

  // useEffect

  // function

  return (
    <div id="chart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={350}
        width={"100%"}
      />
    </div>
  );
};

export default ReportChartDayOfWeek;
