import { useState, useEffect } from "react";
import { Button, Form, Card, Pagination, Table } from "react-bootstrap";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  billGetMany,
  billGetCount,
} from "../../../services/therdy/bill.service";
import moment from "moment";
import { useQuery } from "react-query";

export default function BillPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // state
  const pagination = parseInt(searchParams.get("page") || "1", 10);
  const [totalRecord, setTotalRecord] = useState(10);
  const [limit] = useState(10); // Add limit state
  const [searchTerm, setSearchTerm] = useState(""); // Add searchTerm state

  // Fetch total count of bills
  useEffect(() => {
    const fetchTotalCount = async () => {
      const findby: any = { p: "userId" };
      if (searchTerm) {
        findby.code = searchTerm;
      }
      const count = await billGetCount({ findby, token: "" });
      setTotalRecord(count.count);
    };

    fetchTotalCount();
  }, [searchTerm]);

  // useQuery
  const { data: ordersData } = useQuery(
    ["bills", pagination, limit, searchTerm], // Include searchTerm in the query key
    async () => {
      const findby: any = { p: "userId" };
      if (searchTerm) {
        findby.code = searchTerm;
      }
      return await billGetMany({
        findby,
        token: "",
        skip: (pagination - 1) * limit, // Calculate skip based on pagination and limit
        limit: limit,
      });
    }
  );

  return (
    <div className="p-2 md:p-4">
      <Breadcrumb>
        <Breadcrumb.Item>THERDY CO</Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍການອໍເດີ</Breadcrumb.Item>
      </Breadcrumb>

      <div className="flex gap-2 py-2">
        <Form.Control
          style={{ maxWidth: 220 }}
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm on input change
        />
        <Button
          variant="primary"
          onClick={() => setSearchParams({ page: "1" })}
        >
          ຄົ້ນຫາ
        </Button>
      </div>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-2 mb-4">
        {ordersData?.map((value: any) => (
          <Card
            className={
              value?.status === "CREATED" ? "border-blue-600 p-2" : "p-2"
            }
          >
            <div>
              <div className="text-sm">
                ID: <span className="font-bold">{value?._id ?? "--"}</span>
              </div>
              <div className="text-sm">
                CODE: <span className="font-bold">{value?.code ?? "--"}</span>
              </div>
              <div className="text-sm">
                TOTAL:{" "}
                <span className="font-bold text-primary text-lg">
                  {value?.total ?? "--"}
                </span>
              </div>
              <div className="text-sm">
                CUSTOMER:{" "}
                <span className="font-bold underline">
                  {value?.userId?.username ?? "--"}
                </span>
              </div>
              <hr />
              <div>
                {value?.orders?.map((e: any) => (
                  <div>
                    <div>
                      ຊື່:{" "}
                      <code className="bg-gray-100 text-neutral-600 font-mono text-sm px-2 py-1 rounded">
                        {e?.productPackageRef?.packageGame}
                      </code>
                    </div>
                    {e?.productPackageRef?.payload?.map((item: any) => (
                      <div className="text-sm ">
                        <code className="bg-gray-100 text-neutral-600 font-mono text-sm px-2 py-1 rounded">
                          {item?.title}
                        </code>
                        :{" "}
                        <code className="bg-gray-100 text-blue-500 font-mono text-sm px-2 py-1 rounded">
                          {item?.value}
                        </code>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <hr />
              <div className="text-sm">
                DATE:{" "}
                <span className="font-bold">
                  {value?.createdAt
                    ? moment(value?.createdAt).format("DD/MM/YYYY LT")
                    : "--"}
                </span>
              </div>
            </div>
            <div className="h-full flex flex-col justify-end">
              <Button
                onClick={() =>
                  navigate("/therdy/bill/detail?billId=" + value?._id)
                }
              >
                View
              </Button>
            </div>
          </Card>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          bottom: 20,
        }}
      >
        <ReactPaginate
          previousLabel={
            <span className="glyphicon glyphicon-chevron-left">{`ກ່ອນໜ້າ`}</span>
          }
          nextLabel={
            <span className="glyphicon glyphicon-chevron-right">{`ຕໍ່ໄປ`}</span>
          }
          breakLabel={<Pagination.Item disabled>...</Pagination.Item>}
          breakClassName={"break-me"}
          pageCount={Math.ceil(totalRecord / limit)} // Calculate page count based on totalPagination and limit
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={(e) => {
            setSearchParams({ page: (e?.selected + 1).toString() });
          }}
          containerClassName={"pagination justify-content-center"} // Bootstrap class for centering
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          activeClassName={"active"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
        />
      </div>
    </div>
  );
}
