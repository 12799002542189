import axios from "axios";
import { WarningNetworkFailure } from "../../helpers/alertsweet";

const END_POINT_SEVER =
  process.env.REACT_APP_THERDY_API_URL || "https://minion-api.nonlub.com";

// @ts-ignore
export const paymentGetMany = async (findby, token) => {
  try {
    const url = END_POINT_SEVER + "/payment/get-many?" + findby;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error) {
    // @ts-ignore
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    // @ts-ignore
    return { error: true, message: error.message };
  }
};

export const paymentSmsCallback = async (
  body: { platform: string; text: string },
  token: string
) => {
  try {
    const url = END_POINT_SEVER + "/payment/sms-callback";
    const res = await axios.post(url, body, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

// @ts-ignore
export const lockPriceGetMany = async (findby, token) => {
  try {
    const url = END_POINT_SEVER + "/payment/lockprice/get-many?" + findby;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error) {
    // @ts-ignore
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    // @ts-ignore
    return { error: true, message: error.message };
  }
};
