import { Modal, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import UploadImageFormik from "../../UploadImageFormik";
import CustomInput from "../../CustomInput";
import CustomSelect from "../../CustomSelect";
import validationSchema from "../../../utils/validationSchema";
import { productPackageCreateOne } from "../../../services/therdy/product.service";
import { errorAdd, successAdd } from "../../../helpers/sweetalert";
import { paymentSmsCallback } from "../../../services/therdy/payment.service";

interface PopUpAddTransactionProps {
  open: boolean;
  onClose: () => void;
  productId: string;
}

export default function PopUpAddTransaction({
  open,
  onClose,
  productId,
}: PopUpAddTransactionProps) {
  const onSubmit = async (values: any) => {
    const { error } = await paymentSmsCallback(values, "");
    if (error) {
      errorAdd("error something");
    } else {
      successAdd("success");
      onClose();
    }
  };

  return (
    <Modal show={open} onHide={onClose} keyboard={false}>
      <Modal.Header>
        <Modal.Title>Add Transaction</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{}}
        validationSchema={validationSchema.createPaymentTransaction}
        onSubmit={onSubmit}
      >
        <Form>
          <Modal.Body>
            <div className="gap-[10px]">
              <CustomSelect
                require
                label="Bank"
                name="platform"
                options={[
                  {
                    value: "VMB",
                    label: "MB Laos Bank",
                  },
                  {
                    value: "BCEL",
                    label: "BCEL",
                  },
                  {
                    value: "LDB",
                    label: "LDB",
                  },
                ]}
              />
              <CustomInput
                as="textarea"
                require
                label="Text"
                name="text"
                type="text"
                placeholder="-- TEXT --"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
}
