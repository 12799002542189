import { Modal, Button, InputGroup } from "react-bootstrap";
import { Formik, Form } from "formik";
import UploadImageFormik from "../../UploadImageFormik";
import CustomInput from "../../CustomInput";
import CustomSelect from "../../CustomSelect";
import validationSchema from "../../../utils/validationSchema";
import { productCreateOne } from "../../../services/therdy/product.service";
import { errorAdd, successAdd } from "../../../helpers/sweetalert";
import PayloadInput from "../../PayloadInput";
import { Product } from "../../../interfaces/Product";
import { useState } from "react";

interface PopUpAddProductProps {
  open: boolean;
  onClose: () => void;
}

export default function PopUpAddProduct({
  open,
  onClose,
}: PopUpAddProductProps) {
  const [payloads, setPayloads] = useState<any[]>([]);

  const handleAddPayload = () => {
    setPayloads([...payloads, { title: "", value: "" }]);
  };

  const handleDeletePayload = (index: number) => {
    const newPayloads = payloads.filter((_, i) => i !== index);
    setPayloads(newPayloads);
  };

  const onSubmit = async (values: any) => {
    const { error } = await productCreateOne({ ...values }, "");
    if (error) {
      errorAdd("error something");
    } else {
      successAdd("success");
      onClose();
    }
  };
  return (
    <Modal show={open} onHide={onClose} keyboard={false}>
      <Modal.Header>
        <Modal.Title>Create Product</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{ payload: payloads }}
        validationSchema={validationSchema.createProduct}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <Modal.Body>
              <div className="gap-[10px]">
                <UploadImageFormik
                  commentText="500x500"
                  name="image"
                  style={{ width: "200px", height: "200px" }}
                />
                <CustomInput
                  require
                  label="Product Name"
                  name="name"
                  type="text"
                  placeholder="-- Package Name --"
                />
                <CustomSelect
                  require
                  label="Show on"
                  name="active"
                  options={[
                    {
                      value: "ADMIN",
                      label: "Admin Only",
                    },
                    {
                      value: "VIP",
                      label: "VIP Only",
                    },
                    {
                      value: "PUBLIC",
                      label: "ALL",
                    },
                  ]}
                />
                <CustomSelect
                  require
                  label="Platform"
                  name="type"
                  options={[
                    {
                      label: "Control By Admin",
                      value: "ADMIN",
                    },
                    {
                      label: "moogold.com",
                      value: "MOOGOLD",
                    },
                  ]}
                />
              </div>
              <label>payload</label>
              <div className="p-2 border">
                {values?.payload?.map((e: any, i: any) => (
                  <InputGroup
                    key={i}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 80px",
                    }}
                  >
                    <PayloadInput
                      require
                      label={"Payload_" + (i + 1)}
                      name={`payload[${i}].title`}
                      type="text"
                      placeholder="-- title --"
                    />
                    <PayloadInput
                      label="-"
                      name={`payload[${i}].value`}
                      type="text"
                      placeholder="-- value --"
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>-</label>
                      <Button onClick={() => handleDeletePayload(i)}>
                        Delete
                      </Button>
                    </div>
                  </InputGroup>
                ))}
              </div>
              <Button
                variant="outline-primary"
                style={{ width: "100%" }}
                onClick={handleAddPayload}
              >
                Add Payload
              </Button>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                ບັນທຶກ
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
