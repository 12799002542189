import axios from "axios";
import { WarningNetworkFailure } from "../../helpers/alertsweet";

const END_POINT_SEVER =
  process.env.REACT_APP_THERDY_API_URL || "https://minion-api.nonlub.com";
// const END_POINT_SEVER = "http://localhost:7070";

interface Bill {
  // Define the Bill interface based on the expected structure
}

export const billGetMany = async ({
  findby,
  token,
  skip,
  limit,
}: {
  findby: Record<string, any>;
  token: string;
  skip: number;
  limit: number;
}): Promise<Bill[]> => {
  try {
    const queryParams = new URLSearchParams({
      ...findby,
      skip: skip.toString(),
      limit: limit.toString(),
    }).toString();
    const url = `${END_POINT_SEVER}/bill/get-many?${queryParams}`;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    throw error;
  }
};

export const billGetCount = async ({
  findby,
  token,
}: {
  findby: Record<string, any>;
  token: string;
}): Promise<{ count: number }> => {
  try {
    const queryParams = new URLSearchParams({
      ...findby,
    }).toString();
    const url = `${END_POINT_SEVER}/bill/get-count?${queryParams}`;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    throw error;
  }
};

export const billGetOne = async (_id: string, token: string): Promise<Bill> => {
  try {
    const url = END_POINT_SEVER + "/bill/get-one/" + _id;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    throw error;
  }
};

export const billSmsCallback = async (
  body: { text: string },
  token: string
): Promise<any> => {
  try {
    const url = END_POINT_SEVER + "/bill/sms-callback";
    const res = await axios.post(url, body, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    throw error;
  }
};

export const billDeleteOne = async (
  _id: string,
  token: string
): Promise<any> => {
  try {
    const url = END_POINT_SEVER + "/bill/delete-one/" + _id;
    const res = await axios.delete(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    throw error;
  }
};
