import React, { useEffect, useState } from "react";
import { Button, Card, Pagination, Table } from "react-bootstrap";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import useQuery from "../../../helpers/useQuery";
import { billGetOne } from "../../../services/therdy/bill.service";
import { orderGetMany } from "../../../services/therdy/order.service";
import PopUpOrder from "../../../components/popup/therdy/PopUpOrder";

export default function BillDetailPage() {
  const { billId } = useQuery();

  const _id = billId;

  // state
  const [pagination, setPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(10);
  const [billData, setBillData] = useState<any>();
  const [orderData, setOrderData] = useState<any[]>([]);

  const [popup, setPopup] = useState<any>({});

  // store
  // const { storeDetail }: any = useStore();

  // useEffect
  useEffect(() => {
    getData();
  }, []);
  // function
  const getData = async () => {
    try {
      const data = await billGetOne(_id, "");
      const _packages = await orderGetMany(
        "billId=" + _id + "&p=productId",
        ""
      );
      setOrderData(_packages);
      console.log(data);
      setBillData(data);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <React.Fragment>
      <div className="p-2 md:p-4">
        <Breadcrumb>
          <Breadcrumb.Item>THERDY CO</Breadcrumb.Item>
          <Breadcrumb.Item>ລາຍການອໍເດີ</Breadcrumb.Item>
          <Breadcrumb.Item active>{billData?.name || _id}</Breadcrumb.Item>
        </Breadcrumb>

        <Card border="primary" className="mb-2">
          <Card.Header className="flex items-center">
            ລາຍລະອຽດສິນຄ້າ
          </Card.Header>
          <Card.Body>
            <div>
              <div className="flex-1">
                <div>_id: {billData?._id || "--"}</div>
                <div>ref: {billData?.code || "--"}</div>
                <div>status: {billData?.status || "--"}</div>
                <div>total: {billData?.total || "--"}</div>
                <div>
                  Date:{" "}
                  {billData?.createdAt
                    ? moment(billData?.createdAt).format("DD/MM/YYYY LT")
                    : "--"}
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
          {orderData?.map((value: any, index: any) => (
            <Card className={"p-2"}>
              <div className="mb-4">
                <div className="text-lg text-primary">
                  {value?.productId?.name ?? "--"}
                </div>
                <div className="text-sm">
                  ID: <span className="font-bold">{value?._id ?? "--"}</span>
                </div>
                <div className="text-sm">
                  CODE: <span className="font-bold">{value?.code ?? "--"}</span>
                </div>
                <hr />
                <div>
                  <div>
                    <div>
                      Package:{" "}
                      <code className="bg-gray-100 text-neutral-600 font-mono text-sm px-2 py-1 rounded">
                        {value?.productPackageRef?.packageGame}
                      </code>
                    </div>
                    {value?.productPackageRef?.payload?.map((item: any) => (
                      <div className="text-sm ">
                        <code className="bg-gray-100 text-neutral-600 font-mono text-sm px-2 py-1 rounded">
                          {item?.title}
                        </code>
                        :{" "}
                        <code className="bg-gray-100 text-blue-500 font-mono text-sm px-2 py-1 rounded">
                          {item?.value}
                        </code>
                      </div>
                    ))}
                  </div>
                </div>
                <hr />
                <div className="text-sm">
                  DATE:{" "}
                  <span className="font-bold">
                    {value?.createdAt
                      ? moment(value?.createdAt).format("DD/MM/YYYY LT")
                      : "--"}
                  </span>
                </div>
              </div>
              <div>
                <Button
                  variant="outline-primary"
                  onClick={() => setPopup({ PopUpOrder: value?._id })}
                  className="w-full"
                >
                  Update
                </Button>
              </div>
            </Card>
          ))}
        </div>
      </div>

      <PopUpOrder
        orderId={popup?.PopUpOrder}
        open={!!popup?.PopUpOrder}
        onClose={() => {
          setPopup({});
        }}
        callback={getData}
      />
    </React.Fragment>
  );
}
