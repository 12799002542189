import axios from "axios";

const END_POINT_SEVER =
  process.env.REACT_APP_THERDY_API_URL || "https://minion-api.nonlub.com";

export const moogoldGetOne = async (findby: string, token: string) => {
  const url = END_POINT_SEVER + "/moogold/get-one" + "?" + findby;
  const res = await axios.get(url, {
    headers: { authorization: "Bearer " + token },
  });
  return res.data;
};
